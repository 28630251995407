<template>
    <ddcard class="box-card" :collapsed="true" :inactive="!active">
        <div slot="title" class="clearfix">
            <el-switch v-model="active" :active-value="1" :inactive-value="0" />
            <span style="margin-left: 20px;">SmartBill</span>
        </div>

        <Box type="info" :active="true" style="margin-top: 0; margin-bottom: 10px;">
            <div slot="box-body">
                <ul style="font-size: 12px;">
                    <li>Adresa de email reprezinta adresa de email asociata contului SmartBill</li>
                    <li>Token-ul se poate obtine Contul Meu > Integrari > Magazine online</li>
                </ul>
            </div>
        </Box>

        <el-row v-if="active" :gutter="30">
            <el-col :xs="24" :md="12">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                        <el-form-item :label="$t('sales.billing.email')" prop="smartbill.email">
                            <el-input v-model="email"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12">
                        <el-form-item :label="$t('sales.billing.token')" prop="smartbill.token">
                            <el-input v-model="token"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.product_reference')" prop="smartbill.product_reference">
                            <el-select class="fullWidth" v-model="product_reference" filterable>
                                <el-option
                                    value="products_model"
                                    :label="$t('sales.billing.products_model')"
                                ></el-option>
                                <el-option value="vendor_ext_id" :label="$t('sales.billing.vendor_ext_id')"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.invoice_serie')" prop="smartbill.invoice_serie">
                            <el-input v-model="invoice_serie"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.proforma_serie')" prop="smartbill.proforma_serie">
                            <el-input v-model="proforma_serie"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.shipping_box_code')" prop="fgo.shipping_box_code">
                            <el-input v-model="shipping_box_code"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-col>

            <el-col :xs="24" :md="12">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.warehouse')" prop="smartbill.warehouse">
                            <el-input v-model="warehouse"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.warehouse_unit')" prop="smartbill.warehouse_unit">
                            <el-input v-model="warehouse_unit"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.shipping_type')" prop="smartbill.shipping_type">
                            <el-select class="fullWidth" v-model="shipping_type" filterable>
                                <el-option value="product" :label="$t('sales.billing.product')"></el-option>
                                <el-option value="service" :label="$t('sales.billing.service')"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.shipping_code')" prop="smartbill.shipping_code">
                            <el-input v-model="shipping_code"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24" :md="12">
                        <el-checkbox
                            v-model="issue_payment"
                            :true-label="1"
                            :false-label="0"
                            :label="$t('sales.billing.issue_payment')"
                        />
                    </el-col>

                    <el-col v-if="issue_payment" :span="24" :md="12">
                        <el-checkbox
                            v-model="payment_tax"
                            :true-label="1"
                            :false-label="0"
                            :label="$t('sales.billing.payment_tax')"
                        />
                    </el-col>

                    <el-col :span="24">
                        <el-checkbox
                            v-model="save_customer"
                            :true-label="1"
                            :false-label="0"
                            :label="$t('sales.billing.save_customer')"
                        />
                    </el-col>

                    <el-col :span="24">
                        <el-checkbox
                            v-model="synchronize_stoc"
                            :true-label="1"
                            :false-label="0"
                            :label="$t('sales.billing.synchronize_stoc')"
                        />
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </ddcard>
</template>

<script>
import { sync, get } from 'vuex-pathify';

export default {
    name: 'Smartbill',
    computed: {
        active: sync('account/billing@smartbill.active'),
        email: sync('account/billing@smartbill.email'),
        token: sync('account/billing@smartbill.token'),
        product_reference: sync('account/billing@smartbill.product_reference'),
        invoice_serie: sync('account/billing@smartbill.invoice_serie'),
        proforma_serie: sync('account/billing@smartbill.proforma_serie'),
        warehouse: sync('account/billing@smartbill.warehouse'),
        warehouse_unit: sync('account/billing@smartbill.warehouse_unit'),
        shipping_type: sync('account/billing@smartbill.shipping_type'),
        shipping_code: sync('account/billing@smartbill.shipping_code'),
        shipping_box_code: sync('account/billing@fgo.shipping_box_code'),
        issue_payment: sync('account/billing@smartbill.issue_payment'),
        payment_tax: sync('account/billing@smartbill.payment_tax'),
        save_customer: sync('account/billing@smartbill.save_customer'),
        synchronize_stoc: sync('account/billing@smartbill.synchronize_stoc')
    },
    components: {
        ddcard: () => import('@/components/DDCard'),
        Box: () => import('vue-info-box-element')
    }
};
</script>
